import React from "react"

import {
  PageNavigationWrapperStyled,
  PageNavigationStyled,
  PageNavigationItemStyled,
  PageNavigationItemContentStyled,
  PageNavigationItemHeadStyled,
  PageNavigationItemInfoStyled,
  PageNavigationItemActionStyled,
  PageNavigationItemBgStyled,
  PageNavigationItemLinkStyled,
} from "./styles"
import IconButton from "./../IconButton"

interface IProps {
  prev?: object
  next?: object
}

const PageNavigation: React.FC<IProps> = ({ prev, next, prevImg, nextImg }) => {
  return (
    <PageNavigationWrapperStyled>
      <PageNavigationStyled>
        {prev && (
          <PageNavigationItemStyled>
            <PageNavigationItemLinkStyled to={`/${prev.data.Slug}`}>
              <PageNavigationItemBgStyled>
                <img src={prevImg.node.fluid.src} />
              </PageNavigationItemBgStyled>
              <PageNavigationItemContentStyled>
                <PageNavigationItemHeadStyled>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: prev.data.SidebarBrandName,
                    }}
                  />
                </PageNavigationItemHeadStyled>
                {prev.data.Score && (
                  <PageNavigationItemInfoStyled>
                    Score: <strong>{prev.data.Score}</strong>
                  </PageNavigationItemInfoStyled>
                )}
                <PageNavigationItemActionStyled>
                  <IconButton
                    as="div"
                    text="Read more"
                    isInternalLink
                    isRotatedIcon
                  />
                </PageNavigationItemActionStyled>
              </PageNavigationItemContentStyled>
            </PageNavigationItemLinkStyled>
          </PageNavigationItemStyled>
        )}
        {next && (
          <PageNavigationItemStyled>
            <PageNavigationItemLinkStyled to={`/${next.data.Slug}`}>
              <PageNavigationItemBgStyled>
                <img src={nextImg.node.fluid.src} />
              </PageNavigationItemBgStyled>
              <PageNavigationItemContentStyled>
                <PageNavigationItemHeadStyled>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: next.data.SidebarBrandName,
                    }}
                  />
                </PageNavigationItemHeadStyled>
                {next.data.Score && (
                  <PageNavigationItemInfoStyled>
                    Score: <strong>{next.data.Score}</strong>
                  </PageNavigationItemInfoStyled>
                )}
                <PageNavigationItemActionStyled>
                  <IconButton as="div" text="Read more" isInternalLink />
                </PageNavigationItemActionStyled>
              </PageNavigationItemContentStyled>
            </PageNavigationItemLinkStyled>
          </PageNavigationItemStyled>
        )}
      </PageNavigationStyled>
    </PageNavigationWrapperStyled>
  )
}

export default PageNavigation
