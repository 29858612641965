import styled from "styled-components"

import { media } from "./../../common/MediaQueries"

const ChartItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  @media ${media.phone} {
    flex-direction: row;
    padding: 16px 0;
  }
`

const ChartItemHeadingStyled = styled.div`
  width: 100%;
  margin-bottom: 16px;
  @media ${media.phone} {
    width: 180px;
    padding-right: 32px;
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    width: 230px;
  }
`

const ChartItemContentStyled = styled.div`
  width: 100%;
  @media ${media.phone} {
    width: calc(100% - 180px);
  }
  @media ${media.tablet} {
    width: calc(100% - 230px);
  }
`

const ChartItemContentGraphColumnStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  width: 100%;
  height: 32px;
  color: ${({ theme }) => theme.colors.white};
`

const ChartItemContentGraphAdditionalStyled = styled.span`
  color: ${({ theme }) => theme.colors.black};
  padding-left: 8px;
`

const ChartItemContentGraphStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamilies.publico};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.black};

  &:first-child {
    ${ChartItemContentGraphColumnStyled} {
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
  &:last-child {
    ${ChartItemContentGraphColumnStyled} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export {
  ChartItemStyled,
  ChartItemHeadingStyled,
  ChartItemContentStyled,
  ChartItemContentGraphStyled,
  ChartItemContentGraphColumnStyled,
  ChartItemContentGraphAdditionalStyled,
}
