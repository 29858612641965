import styled from "styled-components"

import { media } from "./../../common/MediaQueries"

const CompanySidebarStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  z-index: 2;

  @media ${media.phone} {
    flex-direction: row;
  }

  @media ${media.tablet} {
    flex-direction: column;
  }
`

const CompanySidebarContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media ${media.phone} {
    width: calc(100% - 228px);
    padding-right: 36px;
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    width: 100%;
    padding-right: 0;
  }
`

const CompanySidebarLogoStyled = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  line-height: 1.2;
  margin-bottom: 38px;

  svg {
    height: 104px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
    }
    @media ${media.tablet} {
      height: 124px;
    }
  }

  img {
    max-width: 238px;
    width: 100%;
  }
`

const CompanySidebarInfoStyled = styled.div`
  display: flex;

  @media ${media.tablet} {
    flex-direction: column;
    margin-bottom: 32px;
    max-width: 220px;
  }
`

const CompanySidebarInfoTopStyled = styled.div`
  width: 50%;
  padding-right: 10px;

  @media ${media.tablet} {
    padding-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }
`

const CompanySidebarInfoBotStyled = styled.div`
  width: 50%;
  @media ${media.tablet} {
    width: 100%;
  }
`

const CompanySidebarTestimonialsStyled = styled.div`
  @media ${media.phone} {
    width: 228px;
  }
  @media ${media.tablet} {
    width: 100%;
  }
`

const CompanySidebarInfoListStyled = styled.ul``

const CompanySidebarInfoListItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  strong {
    display: block;
  }
  a {
    word-break: break-word;
    &:hover {
      text-decoration: underline;
    }
  }
`

export {
  CompanySidebarStyled,
  CompanySidebarContentStyled,
  CompanySidebarLogoStyled,
  CompanySidebarInfoStyled,
  CompanySidebarInfoTopStyled,
  CompanySidebarInfoBotStyled,
  CompanySidebarTestimonialsStyled,
  CompanySidebarInfoListStyled,
  CompanySidebarInfoListItemStyled,
}
