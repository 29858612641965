import React from "react"

import {
  ChartSectionStyled,
  ChartSectionHeadStyled,
  ChartSectionLegendStyled,
  ChartSectionListStyled,
  ChartSectionListItemStyled,
} from "./styles"
import { Typo1 } from "./../Typography"
import Legend from "./../Legend"
import ChartItem from "./../ChartItem"

interface IProps {
  heading?: string
  legend?: []
  items?: []
}

const ChartSection: React.FC<IProps> = ({ data }) => {
  const { title, legendBlue, content } = data

  const legendData = [title, legendBlue]

  return (
    <ChartSectionStyled>
      {title && (
        <ChartSectionHeadStyled>
          <Typo1 as="h2">{title} vs sector average, across 10 criteria</Typo1>
        </ChartSectionHeadStyled>
      )}
      <ChartSectionLegendStyled>
        <Legend items={legendData} />
      </ChartSectionLegendStyled>
      <ChartSectionListStyled>
        {content.map(el => (
          <ChartSectionListItemStyled>
            <ChartItem
              heading={el.data.title}
              firstChart={el.data.firstChart}
              secondChart={el.data.secondChart}
            />
          </ChartSectionListItemStyled>
        ))}
      </ChartSectionListStyled>
    </ChartSectionStyled>
  )
}

export default ChartSection
