import React from "react"

import {
  CompanyHeadStyled,
  CompanyHeadNumberStyled,
  CompanyHeadTextStyled,
} from "./styles"
import Image from "./../Image"

interface IProps {
  heading?: React.ReactNode
  number?: React.ReactNode
}

const CompanyHead: React.FC<IProps> = ({ heading, number }) => {
  return (
    <CompanyHeadStyled>
      <CompanyHeadNumberStyled src={number ? number.node.fluid.src : ""} />
      <CompanyHeadTextStyled>{heading}</CompanyHeadTextStyled>
    </CompanyHeadStyled>
  )
}

export default CompanyHead
