import styled from "styled-components"
import { padding } from "styled-system"

import { media } from "./../../common/MediaQueries"

const ContentBlockStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
`

const ContentBlockHeadStyled = styled.div`
  display: none;
  @media ${media.tablet} {
    display: block;
    width: 100%;
    margin-bottom: 64px;
  }
`

const ContentBlockSectionStyled = styled.div`
  width: 100%;
  ${padding};
`

export { ContentBlockStyled, ContentBlockHeadStyled, ContentBlockSectionStyled }
