import React from "react"

import {
  FeaturesStyled,
  FeaturesHeadStyled,
  FeaturesContentStyled,
} from "./../styles"
import { Typo3 } from "./../../Typography"
import { Flex } from "./../../Flex"
import FeatureBox from "./../../FeatureBox"
import theme from "./../../../themes/theme"

import SingleMindedIcon from "./../../../assets/images/svg/single-minded-icon.svg"
import BrandNarrativeIcon from "./../../../assets/images/svg/brand-narrative-icon.svg"
import DistinctIcon from "./../../../assets/images/svg/distinct-icon.svg"
import ConsistentIcon from "./../../../assets/images/svg/consistent-icon.svg"
import ClearIcon from "./../../../assets/images/svg/clear-icon.svg"
import EsgIcon from "./../../../assets/images/svg/esg-icon.svg"
import AutheticIcon from "./../../../assets/images/svg/authetic-icon.svg"
import MessagingIcon from "./../../../assets/images/svg/messaging-icon.svg"
import VisualIcon from "./../../../assets/images/svg/visual-icon.svg"
import DigitalIcon from "./../../../assets/images/svg/digital-icon.svg"

interface IProps {
  heading?: string
  data?: []
}

const Strengths: React.FC<IProps> = ({ heading, data }) => {
  const { str1, str2, str3, str4, str5, str6, str7, str8, str9, str10 } = data

  return (
    <FeaturesStyled>
      {heading && (
        <FeaturesHeadStyled>
          <Typo3 as="h2" fontWeight={theme.fontWeight.bold}>
            {heading}
          </Typo3>
        </FeaturesHeadStyled>
      )}
      {data && (
        <FeaturesContentStyled>
          <Flex flexWrap={["wrap", ""]}>
            {str1 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Single-minded, overarching brand idea",
                  icon: <SingleMindedIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str2 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Coherent brand narrative across business units",
                  icon: <BrandNarrativeIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str3 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Clear and compelling purpose",
                  icon: <ClearIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str4 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Clear ESG strategy driven by purpose",
                  icon: <EsgIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str5 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Authentic and ownable values",
                  icon: <AutheticIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str6 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Distinct and engaging personality",
                  icon: <DistinctIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str7 == "TRUE" ? (
              <FeatureBox
                data={{
                  title:
                    "Tailored proposition and messaging for each stakeholder group",
                  icon: <MessagingIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str8 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Standout visual style",
                  icon: <VisualIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str9 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Consistent use of brand identity",
                  icon: <ConsistentIcon />,
                }}
              />
            ) : (
              ""
            )}
            {str10 == "TRUE" ? (
              <FeatureBox
                data={{
                  title: "Seamless digital brand experience",
                  icon: <DigitalIcon />,
                }}
              />
            ) : (
              ""
            )}
          </Flex>
        </FeaturesContentStyled>
      )}
    </FeaturesStyled>
  )
}

export default Strengths
