import styled from "styled-components"

const ChartSectionStyled = styled.div``

const ChartSectionHeadStyled = styled.div`
  margin-bottom: 12px;
`

const ChartSectionLegendStyled = styled.div`
  margin-bottom: 14px;
`

const ChartSectionListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
`

const ChartSectionListItemStyled = styled.li`
  border-bottom: 3px solid ${({ theme }) => theme.colors.grey};
  width: 100%;
`

export {
  ChartSectionStyled,
  ChartSectionHeadStyled,
  ChartSectionLegendStyled,
  ChartSectionListStyled,
  ChartSectionListItemStyled,
}
