import React from "react"

import {
  LegendStyled,
  LegendItemStyled,
  LegendItemColorStyled,
  LegendItemTextStyled,
} from "./styles"

interface IProps {
  items: []
}

const Legend: React.FC<IProps> = ({ items }) => {
  return (
    <LegendStyled>
      {items.map(el => (
        <LegendItemStyled>
          <LegendItemColorStyled />
          <LegendItemTextStyled>{el}</LegendItemTextStyled>
        </LegendItemStyled>
      ))}
    </LegendStyled>
  )
}

export default Legend
