import styled from "styled-components"
import { Link } from "gatsby"

import { transition } from "./../../common/mixins"
import { media } from "./../../common/MediaQueries"
import { IconButtonStyled } from "./../../components/IconButton/styles"

const PageNavigationWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
`

const PageNavigationStyled = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  @media ${media.tablet} {
    flex-direction: column;
  }
`

const PageNavigationItemLinkStyled = styled(Link)`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 4px 16px 6px;

  @media ${media.phone} {
    padding: 16px 6px 16px 16px;
  }
`

const PageNavigationItemContentStyled = styled.div`
  position: relative;
  z-index: 2;
`

const PageNavigationItemHeadStyled = styled.div`
  margin-bottom: 28px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamilies.publico};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  line-height: 1.2;

  svg {
    max-width: 64px;
    height: 28px;
    fill: currentColor;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
      max-height: 32px;
    }
  }

  @media ${media.phone} {
    min-height: 34px;
  }

  @media ${media.tablet} {
    margin-bottom: 26px;
  }
`

const PageNavigationItemInfoStyled = styled.p`
  margin-bottom: 22px;
  line-height: 1.7;
  strong {
    display: block;
  }

  @media ${media.tablet} {
    margin-bottom: 21px;
  }
`

const PageNavigationItemActionStyled = styled.div``

const PageNavigationItemBgStyled = styled.div`
  ${transition()};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px;

  img {
    z-index: 5;
    opacity: 0.3;
    //height: 91px;
    width: auto;
    max-height: 94px;

    @media ${media.phone} {
      max-height: 112px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: auto;
      width: 100%;
    }
  }

  svg {
    position: relative;
    z-index: 5;
    ${transition()};
    width: auto;
    height: 91px;
    opacity: 0.3;
    color: ${({ theme }) => theme.colors.black};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
      max-height: 112px;
    }
  }

  @media ${media.tablet} {
    justify-content: center;
    align-items: flex-end;
    padding: 16px;
  }
`

const PageNavigationItemStyled = styled.li`
  ${transition()};
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.colors.black};
  min-height: 230px;

  &:not(:last-child) {
    margin-right: 2px;
  }

  ${IconButtonStyled} {
    color: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    ${PageNavigationItemBgStyled} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
    ${IconButtonStyled} {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media ${media.tablet} {
    height: auto;
    &:not(:last-child) {
      margin-bottom: 2px;
      margin-right: 0;
    }
  }
`

export {
  PageNavigationWrapperStyled,
  PageNavigationStyled,
  PageNavigationItemStyled,
  PageNavigationItemContentStyled,
  PageNavigationItemHeadStyled,
  PageNavigationItemInfoStyled,
  PageNavigationItemActionStyled,
  PageNavigationItemBgStyled,
  PageNavigationItemLinkStyled,
}
