import styled from "styled-components"

import {
  flex,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  FlexboxProps,
  padding,
  width,
  maxWidth,
} from "styled-system"

type IFlexboxProp = FlexboxProps

const Flex = styled("ul")<IFlexboxProp>`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  ${flex}
  ${flexDirection}
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${padding}
  ${width}
`

const FlexItem = styled("li")<IFlexboxProp>`
  display: flex;
  ${flex}
  ${flexWrap}
  ${padding}
  ${width}
  ${maxWidth}
`

export { Flex, FlexItem }
