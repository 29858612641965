import React from "react"

import {
  CompanySidebarLogoStyled,
  CompanySidebarStyled,
  CompanySidebarInfoStyled,
  CompanySidebarInfoTopStyled,
  CompanySidebarInfoBotStyled,
  CompanySidebarTestimonialsStyled,
  CompanySidebarInfoListStyled,
  CompanySidebarInfoListItemStyled,
  CompanySidebarContentStyled,
} from "./styles"
import { Typo5 } from "./../Typography"
import TestimonialItem from "./../TestimonialItem"
import { websiteUrlFormat } from "./../../common/utils"

interface IProps {
  data: []
  testimonials?: []
}

const CompanySidebar: React.FC<IProps> = ({ data, testimonials }) => {
  const { position, listed, revenue, sector, website, logo, score } = data

  return (
    <CompanySidebarStyled>
      <CompanySidebarContentStyled>
        <CompanySidebarLogoStyled>
          {/* <div dangerouslySetInnerHTML={{ __html: logo }} /> */}
          {logo && <img src={logo[0].url} />}
        </CompanySidebarLogoStyled>
        <CompanySidebarInfoStyled>
          <CompanySidebarInfoTopStyled>
            <CompanySidebarInfoListStyled>
              <CompanySidebarInfoListItemStyled>
                <Typo5>
                  Position: <strong>{position}</strong>
                </Typo5>
              </CompanySidebarInfoListItemStyled>
              {listed && (
                <CompanySidebarInfoListItemStyled>
                  <Typo5>
                    Listed: <strong>{listed}</strong>
                  </Typo5>
                </CompanySidebarInfoListItemStyled>
              )}
              {score && (
                <CompanySidebarInfoListItemStyled>
                  <Typo5>
                    {/* Revenue: <strong>£{moneyFormat(revenue)}</strong> */}
                    Average score: <strong>{score}</strong>
                  </Typo5>
                </CompanySidebarInfoListItemStyled>
              )}
            </CompanySidebarInfoListStyled>
          </CompanySidebarInfoTopStyled>
          <CompanySidebarInfoBotStyled>
            <CompanySidebarInfoListStyled>
              {sector && (
                <CompanySidebarInfoListItemStyled>
                  <Typo5>
                    Sector: <strong>{sector}</strong>
                  </Typo5>
                </CompanySidebarInfoListItemStyled>
              )}
              {website && (
                <CompanySidebarInfoListItemStyled>
                  <Typo5>
                    Website:
                    <strong>
                      <a href={website} target="_blank" rel="noopener">
                        {websiteUrlFormat(website)}
                      </a>
                    </strong>
                  </Typo5>
                </CompanySidebarInfoListItemStyled>
              )}
            </CompanySidebarInfoListStyled>
          </CompanySidebarInfoBotStyled>
        </CompanySidebarInfoStyled>
      </CompanySidebarContentStyled>
      <CompanySidebarTestimonialsStyled>
        <TestimonialItem data={testimonials} />
      </CompanySidebarTestimonialsStyled>
    </CompanySidebarStyled>
  )
}

export default CompanySidebar
