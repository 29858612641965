import styled from "styled-components"

import { media } from "./../../common/MediaQueries"

const CompanyHeadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${media.phone} {
    flex-direction: row;
    align-items: center;
  }
`

const CompanyHeadNumberStyled = styled.img`
  padding-right: 8px;
  height: 73px;
  margin-bottom: 12px;
  width: auto;
  opacity: 0.3;

  @media ${media.phone} {
    padding-right: 16px;

    height: 120px;
    width: auto;
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    padding-right: 40px;
  }
`

const CompanyHeadTextStyled = styled.div`
  max-width: 100%;
  margin-top: -4px;
  word-break: break-word;

  @media ${media.phone} {
    margin-top: 0;
  }
`

export { CompanyHeadStyled, CompanyHeadNumberStyled, CompanyHeadTextStyled }
