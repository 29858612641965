import React from "react"

import {
  ContentBlockStyled,
  ContentBlockHeadStyled,
  ContentBlockSectionStyled,
} from "./styles"
import { Typo3 } from "./../Typography"
import CompanyHead from "./../CompanyHead"
import Strengths from "./../Features/Strengths"
import Opportunities from "./../Features/Opportunities"
import ChartSection from "./../ChartSection"
import theme from "./../../themes/theme"

import { Heading1 } from "./../Typography"

interface IProps {
  numberImg?: React.ReactNode
  title?: string
  description?: string
  content?: string
  strengths?: []
  opportunities?: []
  chart?: []
}

const ContentBlock: React.FC<IProps> = ({
  numberImg,
  title,
  description,
  content,
  strengths,
  opportunities,
  chart,
}) => {
  return (
    <ContentBlockStyled>
      <ContentBlockHeadStyled>
        <CompanyHead
          number={numberImg}
          heading={
            <Heading1 as="h1" fontWeight={theme.fontWeight.black}>
              {title}
            </Heading1>
          }
        />
      </ContentBlockHeadStyled>

      {description && (
        <ContentBlockSectionStyled pb={["32px", "30px", "64px"]}>
          <Typo3>
            <strong>{description}</strong>
          </Typo3>
        </ContentBlockSectionStyled>
      )}
      {content && (
        <ContentBlockSectionStyled pb={["32px", "30px", "64px"]}>
          <Typo3>{content}</Typo3>
        </ContentBlockSectionStyled>
      )}

      <ContentBlockSectionStyled pb={"26px"}>
        <Strengths heading="Strengths" data={strengths} />
      </ContentBlockSectionStyled>

      <ContentBlockSectionStyled pb={["32px", "30px", "64px"]}>
        <Opportunities heading="Opportunities" data={opportunities} />
      </ContentBlockSectionStyled>

      <ContentBlockSectionStyled>
        <ChartSection brand={title} data={chart} />
      </ContentBlockSectionStyled>
    </ContentBlockStyled>
  )
}

export default ContentBlock
