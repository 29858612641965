import React from "react"

import {
  TestimonialItemStyled,
  TestimonialItemMediaStyled,
  TestimonialItemContentStyled,
  TestimonialItemQuoteStyled,
  TestimonialItemNameStyled,
  TestimonialItemDescStyled,
} from "./styles"
import { Typo3, Typo4 } from "./../Typography"
import theme from "./../../themes/theme"

interface IProps {
  data: []
}

const TestimonialItem: React.FC<IProps> = ({ data }) => {
  const { photo, name, company, jobTitle, content } = data

  return (
    <TestimonialItemStyled>
      <TestimonialItemMediaStyled>
        {photo && photo[0].url ? <img src={photo[0].url} alt={name} /> : ""}
      </TestimonialItemMediaStyled>
      <TestimonialItemContentStyled>
        {content && (
          <TestimonialItemQuoteStyled>
            <Typo3 fontWeight={theme.fontWeight.light}>{content}</Typo3>
          </TestimonialItemQuoteStyled>
        )}
        {name && (
          <TestimonialItemNameStyled>
            <Typo4 fontWeight={theme.fontWeight.bold}>{name}</Typo4>
          </TestimonialItemNameStyled>
        )}
        {jobTitle || company ? (
          <TestimonialItemDescStyled>
            <Typo4>
              {jobTitle !== null ? `${jobTitle}` : ""}
              {company !== null && company.replace(/ /g, "") !== "" ? (
                <React.Fragment>
                  , <br />
                  {company}
                </React.Fragment>
              ) : (
                ""
              )}
            </Typo4>
          </TestimonialItemDescStyled>
        ) : (
          ""
        )}
      </TestimonialItemContentStyled>
    </TestimonialItemStyled>
  )
}

export default TestimonialItem
