import styled from "styled-components"

const TestimonialItemStyled = styled.div``

const TestimonialItemMediaStyled = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

const TestimonialItemContentStyled = styled.div``

const TestimonialItemQuoteStyled = styled.div`
  margin-bottom: 15px;
`

const TestimonialItemNameStyled = styled.div``

const TestimonialItemDescStyled = styled.div``

export {
  TestimonialItemStyled,
  TestimonialItemMediaStyled,
  TestimonialItemContentStyled,
  TestimonialItemQuoteStyled,
  TestimonialItemNameStyled,
  TestimonialItemDescStyled,
}
