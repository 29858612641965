import React from "react"

import {
  FeatureBoxStyled,
  FeatureBoxImageWrapperStyled,
  FeatureBoxDescStyled,
} from "./styles"
import { FlexItem } from "./../Flex"
import Container from "./../Container"
import { Typo3 } from "../Typography"

interface IProps {
  data: object
}

const FeatureBox: React.FC<IProps> = ({ data }) => {
  const { title, icon } = data
  return (
    <FlexItem
      flex={["0 0 50%", "0 0 33.333333%", "0 0 33.333333%"]}
      pb={["10px", "20px"]}
    >
      <Container width={["100%", "100%"]} pr={["", "22px"]}>
        <FeatureBoxStyled>
          {icon && (
            <FeatureBoxImageWrapperStyled>{icon}</FeatureBoxImageWrapperStyled>
          )}
          {title && (
            <FeatureBoxDescStyled>
              <Typo3>{title}</Typo3>
            </FeatureBoxDescStyled>
          )}
        </FeatureBoxStyled>
      </Container>
    </FlexItem>
  )
}

export default FeatureBox
