import styled from "styled-components"

const FeaturesStyled = styled.div``

const FeaturesHeadStyled = styled.div`
  margin-bottom: 16px;
`

const FeaturesContentStyled = styled.div``

export { FeaturesStyled, FeaturesHeadStyled, FeaturesContentStyled }
