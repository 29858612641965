import React from "react"

import {
  ChartItemStyled,
  ChartItemHeadingStyled,
  ChartItemContentStyled,
  ChartItemContentGraphStyled,
  ChartItemContentGraphColumnStyled,
  ChartItemContentGraphAdditionalStyled,
} from "./styles"
import { Typo6 } from "./../Typography"

interface IProps {
  heading?: string
  firstChart?: number
  secondChart?: number
}

const ChartItem: React.FC<IProps> = ({ heading, firstChart, secondChart }) => {
  return (
    <ChartItemStyled>
      <ChartItemHeadingStyled>
        {heading && <Typo6>{heading}</Typo6>}
      </ChartItemHeadingStyled>
      <ChartItemContentStyled>
        {firstChart && (
          <ChartItemContentGraphStyled>
            <ChartItemContentGraphColumnStyled
              value={firstChart}
              style={{ width: `${(firstChart * 100) / 10}%` }}
            >
              {firstChart > 1 && firstChart}
            </ChartItemContentGraphColumnStyled>
            {firstChart <= 1 && (
              <ChartItemContentGraphAdditionalStyled>
                {firstChart}
              </ChartItemContentGraphAdditionalStyled>
            )}
          </ChartItemContentGraphStyled>
        )}
        {secondChart && (
          <ChartItemContentGraphStyled>
            <ChartItemContentGraphColumnStyled
              value={secondChart}
              style={{ width: `${(secondChart * 100) / 10}%` }}
            >
              {secondChart > 1 && secondChart}
            </ChartItemContentGraphColumnStyled>
            {secondChart <= 1 && (
              <ChartItemContentGraphAdditionalStyled>
                {secondChart}
              </ChartItemContentGraphAdditionalStyled>
            )}
          </ChartItemContentGraphStyled>
        )}
      </ChartItemContentStyled>
    </ChartItemStyled>
  )
}

export default ChartItem
