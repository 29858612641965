export const moneyFormat = (labelValue: number) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? Math.abs(Number(labelValue)) / 1.0e9 + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? Math.abs(Number(labelValue)) / 1.0e6 + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? Math.abs(Number(labelValue)) / 1.0e3 + "k"
    : Math.abs(Number(labelValue))
}

export const websiteUrlFormat = (url: string) => {
  if (url.substr(-1) == "/") {
    return url
      .substr(0, url.length - 1)
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
  } else {
    return url
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
  }
}
