import styled from "styled-components"

import { customMedia, media } from "./../../common/MediaQueries"

const FeatureBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @media ${customMedia.maxPhone} {
    padding: 8px;
  }
`

const FeatureBoxImageWrapperStyled = styled.div`
  display: inline-block;
  line-height: 1em;
  font-size: ${props => (props.iconSize ? props.iconSize : "58px")};
  margin-bottom: 12px;
  height: 1em;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
  }
  svg {
    width: 58px;
    height: 58px;
  }
`

const FeatureBoxImageStyled = styled.img`
  height: 1em;
`

const FeatureBoxDescStyled = styled.div`
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.fontWeight.light};

  p:not(:last-child) {
    margin-bottom: 10px;
  }
`

export {
  FeatureBoxStyled,
  FeatureBoxImageWrapperStyled,
  FeatureBoxImageStyled,
  FeatureBoxDescStyled,
}
