import styled from "styled-components"

import { media } from "./../../common/MediaQueries"

const LegendStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media ${media.phone} {
    flex-direction: row;
  }
`

const LegendItemColorStyled = styled.div`
  width: 32px;
  height: 8px;
  margin-right: 8px;
`

const LegendItemTextStyled = styled.span`
  font-size: 14px;
  line-height: 1.1;
`

const LegendItemStyled = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  &:first-child {
    ${LegendItemColorStyled} {
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
  &:last-child {
    ${LegendItemColorStyled} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }

  @media ${media.phone} {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`

export {
  LegendStyled,
  LegendItemStyled,
  LegendItemColorStyled,
  LegendItemTextStyled,
}
