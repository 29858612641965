import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import CompanyHead from "./../components/CompanyHead"
import ContentBlock from "./../components/ContentBlock"
import CompanySidebar from "./../components/CompanySidebar"
import PageNavigation from "./../components/PageNavigation"
import { media } from "./../common/MediaQueries"
import { Heading1 } from "./../components/Typography"
import theme from "./../themes/theme"

const TestStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};

  @media ${media.tablet} {
    flex-direction: row;
  }
`
const TestContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 2px;
  margin-bottom: 80px;
  padding: 0 16px;
  @media ${media.phone} {
    margin-bottom: 38px;
    padding-left: 78px;
    padding-right: 0;
  }
  @media ${media.tablet} {
    position: relative;
    flex-direction: row;
    align-items: flex-start;
    width: calc(100% - 132px);
    margin-bottom: 0;
    padding-left: 0;

    justify-content: space-between;
    margin: 0 auto;
    //max-width: 1112px;
    padding: 0 2px 0 24px;

    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const TestContentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1112px;
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const TestSidebarNavStyled = styled.div`
  width: 100%;
  padding: 0 16px;
  @media ${media.phone} {
    padding-left: 78px;
    padding-right: 0;
  }
  @media ${media.tablet} {
    width: 132px;
    padding-left: 0;
    height: 100vh;
  }
`

const TestContentBlockStyled = styled.div`
  width: 100%;
  background: white;
  order: 3;
  @media ${media.phone} {
    padding-right: 76px;
  }
  @media ${media.tablet} {
    order: 1;

    padding-right: 0;
    padding-top: 72px;
    width: auto;
  }
`

const TestContentBlockWrapperStyled = styled.div`
  max-width: 710px;
  width: 100%;
  @media ${media.tablet} {
    padding-right: 32px;
  }
`

const TestContentSidebarStyled = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey};
  order: 2;
  padding: 24px 24px 30px 30px;
  margin-bottom: 32px;
  @media ${media.phone} {
    padding: 24px 76px 24px 32px;
  }
  @media ${media.tablet} {
    width: 270px;
    order: 2;
    margin-bottom: 0;
    padding: 32px 16px 98px 16px;
  }
`

const TestContentHeadingStyled = styled.div`
  order: 1;
  padding: 40px 0;
  @media ${media.phone} {
    padding: 38px 0 32px;
  }
  @media ${media.tablet} {
    display: none;
    padding: 0;
  }
`

const CompanyDetails = ({ data, pageContext }) => {
  const dataset = data.companyDetails.nodes[0].data
  const numberSvgImages = data.allImageSharp.edges

  const sortedNumbersImgArray = numberSvgImages.sort(function (a, b) {
    return (
      Number(a.node.fluid.originalName.match(/(\d+)/g)[0]) -
      Number(b.node.fluid.originalName.match(/(\d+)/g)[0])
    )
  })

  const {
    Name,
    Description,
    Content,
    LogoPng,
    SidebarBrandName,
    Position,
    Listed,
    Revenue,
    Sector,
    Score,
    Website,
    TestimonialsNameSurname,
    TestimonialsCompany,
    TestimonialsContent,
    TestimonialsJobTitle,
    TestimonialsPhotoURL,
    Strength1,
    Strength2,
    Strength3,
    Strength4,
    Strength5,
    Strength6,
    Strength7,
    Strength8,
    Strength9,
    Strength10,
    Opportunity1,
    Opportunity2,
    Opportunity3,
    Opportunity4,
    Opportunity5,
    Opportunity6,
    Opportunity7,
    Opportunity8,
    Opportunity9,
    Opportunity10,
    ChartTitle,
    ChartLegendBlue,
    SingleMindedB,
    SingleMindedI,
    CoherentBrandsB,
    CoherentBrandsI,
    ClearPurposeB,
    ClearPurposeI,
    ActionPlanB,
    ActionPlanI,
    AuthenticValuesB,
    AuthenticValuesI,
    DistinctPersonalityB,
    DistinctPersonalityI,
    TailoredPropositionsB,
    TailoredPropositionsI,
    CutCreatibeB,
    CutCreatibeI,
    ConsistentUseB,
    ConsistentUseI,
    DigitalBrandB,
    DigitalBrandI,
  } = dataset

  const numberImg = sortedNumbersImgArray[Position - 1]

  const sidebarDataObj = {
    logo: LogoPng,
    sidebarBrandName: SidebarBrandName,
    position: Position,
    listed: Listed,
    revenue: Revenue,
    sector: Sector,
    website: Website,
    score: Score,
  }

  const testimonialsDataObj = {
    photo: TestimonialsPhotoURL,
    name: TestimonialsNameSurname,
    company: TestimonialsCompany,
    jobTitle: TestimonialsJobTitle,
    content: TestimonialsContent,
  }

  const strengthsDataObj = {
    str1: Strength1,
    str2: Strength2,
    str3: Strength3,
    str4: Strength4,
    str5: Strength5,
    str6: Strength6,
    str7: Strength7,
    str8: Strength8,
    str9: Strength9,
    str10: Strength10,
  }

  const opportunitiesDataObj = {
    opp1: Opportunity1,
    opp2: Opportunity2,
    opp3: Opportunity3,
    opp4: Opportunity4,
    opp5: Opportunity5,
    opp6: Opportunity6,
    opp7: Opportunity7,
    opp8: Opportunity8,
    opp9: Opportunity9,
    opp10: Opportunity10,
  }

  const chartDataObj = {
    title: ChartTitle,
    legendBlue: ChartLegendBlue,
    content: [
      {
        data: {
          title: "Single-minded, overarching brand idea",
          firstChart: SingleMindedB,
          secondChart: SingleMindedI,
        },
      },
      {
        data: {
          title: "Coherent brand narrative across business units",
          firstChart: CoherentBrandsB,
          secondChart: CoherentBrandsI,
        },
      },
      {
        data: {
          title: "Clear and compelling purpose",
          firstChart: ClearPurposeB,
          secondChart: ClearPurposeI,
        },
      },
      {
        data: {
          title: "Clear ESG strategy driven by purpose",
          firstChart: ActionPlanB,
          secondChart: ActionPlanI,
        },
      },
      {
        data: {
          title: "Authentic and ownable values",
          firstChart: AuthenticValuesB,
          secondChart: AuthenticValuesI,
        },
      },
      {
        data: {
          title: "Distinct and engaging personality",
          firstChart: DistinctPersonalityB,
          secondChart: DistinctPersonalityI,
        },
      },
      {
        data: {
          title:
            "Tailored proposition and messaging for each stakeholder group",
          firstChart: TailoredPropositionsB,
          secondChart: TailoredPropositionsI,
        },
      },
      {
        data: {
          title: "Standout visual style",
          firstChart: CutCreatibeB,
          secondChart: CutCreatibeI,
        },
      },
      {
        data: {
          title: "Consistent use of brand identity",
          firstChart: ConsistentUseB,
          secondChart: ConsistentUseI,
        },
      },
      {
        data: {
          title: "Seamless digital brand experience",
          firstChart: DigitalBrandB,
          secondChart: DigitalBrandI,
        },
      },
    ],
  }

  return (
    <Layout>
      <SEO
        title={`${Name} - B2B’s Brightest Brands results`}
        description="Reporting on the brand’s ranking, scores across 10 criteria, industry average, strengths and opportunities."
      />
      <TestStyled>
        <TestContentStyled>
          <TestContentContainerStyled>
            <TestContentHeadingStyled>
              <CompanyHead
                number={numberImg}
                heading={
                  <Heading1 fontWeight={theme.fontWeight.black}>
                    {Name}
                  </Heading1>
                }
              />
            </TestContentHeadingStyled>
            <TestContentBlockStyled>
              <TestContentBlockWrapperStyled>
                <ContentBlock
                  {...{ numberImg }}
                  title={Name}
                  description={Description}
                  content={Content}
                  strengths={strengthsDataObj}
                  opportunities={opportunitiesDataObj}
                  chart={chartDataObj}
                />
              </TestContentBlockWrapperStyled>
            </TestContentBlockStyled>
            <TestContentSidebarStyled>
              <CompanySidebar
                data={sidebarDataObj}
                testimonials={testimonialsDataObj}
              />
            </TestContentSidebarStyled>
          </TestContentContainerStyled>
        </TestContentStyled>
        <TestSidebarNavStyled>
          <PageNavigation
            prevImg={
              pageContext.prev &&
              sortedNumbersImgArray[Number(pageContext.prev.data.Position) - 1]
            }
            nextImg={
              pageContext.next &&
              sortedNumbersImgArray[Number(pageContext.next.data.Position) - 1]
            }
            prev={pageContext.prev}
            next={pageContext.next}
          />
        </TestSidebarNavStyled>
      </TestStyled>
    </Layout>
  )
}

export default CompanyDetails

export const query = graphql`
  query($id: Int) {
    companyDetails: allAirtable(filter: { data: { Position: { eq: $id } } }) {
      nodes {
        data {
          Name
          Position
          Score
          Sector
          Revenue
          Slug
          LogoPng {
            url
          }
          SidebarBrandName
          Website
          Listed
          Description
          Content
          Strength1
          Strength2
          Strength3
          Strength4
          Strength5
          Strength6
          Strength7
          Strength8
          Strength9
          Strength10
          Opportunity1
          Opportunity2
          Opportunity3
          Opportunity4
          Opportunity5
          Opportunity6
          Opportunity7
          Opportunity8
          Opportunity9
          Opportunity10
          TestimonialsNameSurname
          TestimonialsJobTitle
          TestimonialsCompany
          TestimonialsContent
          TestimonialsPhotoURL {
            url
          }
          ChartTitle
          ChartLegendBlue
          SingleMindedB
          SingleMindedI
          CoherentBrandsB
          CoherentBrandsI
          ClearPurposeB
          ClearPurposeI
          ActionPlanB
          ActionPlanI
          AuthenticValuesB
          AuthenticValuesI
          DistinctPersonalityB
          DistinctPersonalityI
          TailoredPropositionsB
          TailoredPropositionsI
          CutCreatibeB
          CutCreatibeI
          ConsistentUseB
          ConsistentUseI
          DigitalBrandB
          DigitalBrandI
        }
        table
        recordId
      }
    }
    allImageSharp(
      filter: { original: { src: { regex: "/number-ico/" } } }
      sort: { fields: original___src }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 355, quality: 80) {
            originalName
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
